import React from "react";
import MediaQuery from "react-responsive";
import bind from "memoize-bind";
import counterpart from "counterpart";

import MEDIA_QUERIES from "@app/constants/media-queries";
import Spinner from "@generic/common/spinner";
import DocumentTitle from "@generic/common/document-title";
import { loadLanguageUI } from "@app/languages";

import { logos as logoContext } from "@app/utility/require/context/logo";

class ErrorWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        await loadLanguageUI(window.shiftContext.languages.default);
        this.setState({ isLoading: false });
    }

    renderForScreen(smallScreen) {
        const { errorCode, errorMessage } = window;

        return (
            <DocumentTitle>
                <div className={smallScreen ? "shift-small-screen" : ""}>
                    {this.state.isLoading && <Spinner />}
                    {!this.state.isLoading && (
                        <div className="error">
                            <div className="logos">
                                <img src={logoContext("H_blue")} className="shift-logo" />
                            </div>
                            <div className="error-container">{`${errorCode} - ${counterpart(
                                `error.${errorMessage}`
                            )}`}</div>
                        </div>
                    )}
                </div>
            </DocumentTitle>
        );
    }

    render() {
        return <MediaQuery query={MEDIA_QUERIES.SMALL_SCREEN}>{bind(this.renderForScreen, this)}</MediaQuery>;
    }
}

ErrorWrapper.displayName = "ErrorWrapper";

export default ErrorWrapper;
