import "promise-shim";

import React from "react";
import ReactDOM from "react-dom";

import { registerLanguages } from "@app/languages";

import "~/less/error.less";
import ErrorWrapper from "./error-wrapper";
import { loadClientTheme } from "@app/theme";
loadClientTheme();

registerLanguages();

ReactDOM.render(<ErrorWrapper />, document.getElementById("app-container"));
